<template>
  <div class="Buy">
    <!-- 支付 确认 -->
    <div class="Buy-info banner">
      <div class="platformName">雇人用收银台</div>
      <div class="OderName">￥{{ PaymentInfo.price }}</div>
      <div class="Order-day">
        支付剩余时间：
        <!--        <FlipDown :endDate="residueTime(PaymentInfo.time)" :type="3" :theme="1" :timeUnit="['','小时','分','秒']"></FlipDown>-->
        <countdown-timer :end-time="residueTime(PaymentInfo.time)" class="remain-time" />
      </div>
      <div class="Payment-method">
        <!--  -->
        <div class="wx">
          <div class="Payment-info">
            <img src="@/assets/imgs/wx.png" alt />
            <div class="PaymentName">微信支付</div>
          </div>
          <el-radio v-model="PaymentType" label="wx"></el-radio>
        </div>
        <!--  -->
        <!-- <div class="zfb">
          <div class="Payment-info">
            <img src="@/assets/imgs/zfb.png" alt />
            <div class="PaymentName">支付宝支付</div>
          </div>
          <el-radio v-model="PaymentType" label="zfb"></el-radio>
        </div>-->
      </div>
      <!-- 去支付 -->
      <!-- GetOpen -->
      <div class="Payment-submit" @click="GetOpen">去支付</div>
      <div class="Payment-secure">
        <img src="@/assets/imgs/secure.png" alt />
        平台保障资金安全，未服务随时可退
      </div>
    </div>
    <div class="qrcode" ref="qrCodeUrl"></div>
    <!-- 支付弹框 -->
    <Open ref="Open" @GetHis="GetHis" @handleClose="handleClose" />
  </div>
</template>
<script>
import Open from "@/views/Order/Open.vue";
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
import { PayOrderStatusEnum } from "@/constants/pay";

export default {
  data() {
    return {
      PaymentType: "wx",
      PaymentInfo: "",
      timer:       null,
      channelInfo: {
        name: '微信扫码支付',
        code: "wx_native"
      }
    };
  },
  created() {
    // 当获取 不到 路由携带的参数的情况下 返回上一页
    if (!this.$route.params.id) {
      return this.$router.go(-1);
    }
    // 接受到 参数 去赋值
    this.PaymentInfo = this.$route.params;
  },
  computed:   {
    // 计算订单剩余时间
    residueTime() {
      return (time) => {
        let create_time = time + 1000 * 60 * 60 * 2;
        return create_time;
      };
    },
  },
  components: {
    CountdownTimer,
    Open,
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 刷新页面
    GetHis() {
      clearInterval(this.timer);
      this.GetOpen();
    },
    handleClose() {
      clearInterval(this.timer);
    },
    GetOpen() {
      // 出现 加载
      this.$modal.loading("正在调取支付,请稍等...");
      let url = "/admin-api/pay/order/submit";
      this.$axios
          .post(url, {
            id:          this.PaymentInfo.id,
            channelCode: this.channelInfo.code,
            returnUrl:   location.href
          })
          .then((res) => {
            if (res.data.code === 0) {
              // 关闭zzc
              this.$modal.closeLoading();
              this.$refs.Open.Open = true;
              this.$refs.Open.price = this.PaymentInfo.price;
              this.$refs.Open.codeUrl = res.data.data.displayContent;
              this.$refs.Open.time = this.PaymentInfo.time;
              this.createQueryInterval();
            }
          })
          .catch((err) => {
            this.$modal.closeLoading();
            return Promise.reject(err);
          });
      // this.$refs.Open.Open = true;
    },
    
    goReturnUrl(payResult) {
      console.log(payResult)
      // 清理任务
      this.clearQueryInterval();
      
      // 未配置的情况下，只能关闭
      if (!this.returnUrl) {
        this.$router.push({ name: 'PublishedDemandList' })
      }
      
      // const url = this.returnUrl.indexOf('?') >= 0
      //   ? this.returnUrl + '&payResult=' + payResult
      //   : this.returnUrl + '?payResult=' + payResult
      // // 如果有配置，且是 http 开头，则浏览器跳转
      // if (this.returnUrl.indexOf('http') === 0) {
      //   location.href = url;
      // } else {
      //   this.$tab.closePage(() => {
      //     this.$router.push({
      //       path: url
      //     });
      //   });
      // }
    },
    
    /** 轮询查询任务 */
    createQueryInterval() {
      if (this.interval) {
        return
      }
      this.interval = setInterval(() => {
        this.$axios
            .get('/admin-api/pay/order/get?id=' + this.PaymentInfo.id)
            .then(response => {
              // 已支付
              if (response.data.data.status === PayOrderStatusEnum.SUCCESS.status) {
                this.clearQueryInterval();
                this.$message.success('支付成功！');
                this.goReturnUrl('success');
              }
              // 已取消
              if (response.data.data.status === PayOrderStatusEnum.CLOSED.status) {
                this.clearQueryInterval();
                this.$message.error('支付已关闭！');
                this.goReturnUrl('close');
              }
            })
      }, 1000 * 2)
    },
    /** 清空查询任务 */
    clearQueryInterval() {
      // 清空各种弹窗
      this.qrCode = {
        title:   '',
        url:     '',
        visible: false
      }
      // 清空任务
      clearInterval(this.interval)
      this.interval = undefined
    },
  },
};
</script>
<style lang="less" scoped>
.Buy {
  background: #f7f9fa;
  padding: 30px 0;
  
  .Buy-info {
    height: 741px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 0 auto !important;
    padding: 40px;
    box-sizing: border-box;
    
    .platformName {
      font-weight: bold;
      color: #000000;
      line-height: 35px;
      letter-spacing: 2px;
      text-align: center;
      font-size: 30px;
    }
    
    .OderName {
      font-weight: bold;
      color: #000000;
      line-height: 56px;
      font-size: 48px;
      text-align: center;
      margin-top: 40px;
    }
    
    .Order-day {
      width: 308px;
      height: 56px;
      background: #f5f5f5;
      border-radius: 48px 48px 48px 48px;
      margin: 40px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      font-size: 20px;
      
      /deep/ .time-unit {
        color: #333333;
        font-size: 16px;
      }
      
      /deep/ .time-box {
        color: #333333;
        font-size: 16px;
      }
    }
    
    .Payment-method {
      /deep/ .el-radio__label {
        display: none;
      }
      
      /deep/ .el-radio__inner:hover {
        border: 1px solid #209f85;
      }
      
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #209f85;
        background: #ffffff;
      }
      
      /deep/ .el-radio__inner {
        width: 30px;
        height: 30px;
      }
      
      /deep/ .el-radio__inner::after {
        width: 16px;
        height: 16px;
        background-color: #209f85;
      }
      
      .PaymentName {
        font-weight: bold;
        color: #000000;
        line-height: 28px;
        letter-spacing: 2px;
        font-size: 24px;
        margin-left: 30px;
      }
      
      .wx,
      .zfb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
      }
      
      .Payment-info {
        display: flex;
        align-items: center;
      }
      
      padding: 0 15%;
      box-sizing: border-box;
      margin-top: 65px;
    }
    
    .Payment-submit {
      width: 220px;
      height: 46px;
      background: #fc3737;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 23px;
      font-size: 20px;
      cursor: pointer;
      margin: 100px auto 0;
    }
    
    .Payment-secure {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      font-size: 20px;
      margin: 20px auto 0;
      justify-content: center;
      
      img {
        margin-right: 11px;
        width: 18px;
        height: 20px;
      }
    }
  }
}

</style>
